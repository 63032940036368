import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import SigninWidget from "./components/auth/SigninWidget";
import Profile from './components/pages/Profile/Profile';
import Psychographic from './components/pages/Psychographic/Psychographic';
import SocioDemographic from './components/pages/SocioDemographic/SocioDemographic';
import BehavioralDistribution from './components/pages/BehavioralDistribution/BehavioralDistribution';
import DetailsPage from "./components/pages/Details/DetailsPage";

const oktaAuth = new OktaAuth({
  issuer: `${process.env.REACT_APP_OKTA_ISSUER}`,
  clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
  redirectUri: window.location.origin + "/login/callback",
});

const App = (props) => {
  // Function to restore the original URI
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    props.history.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  useEffect(() => {
    localStorage.setItem("historyListFlag", true);
    // localStorage.setItem("historyData", null);
    // localStorage.setItem("activeItem", null);
    localStorage.setItem("historyCollapse", false)
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Route path="/" exact={true} component={SigninWidget} />
      <Route path="/login/callback" component={LoginCallback} />
        <Route path="/Psychographic" component={Psychographic} />
        <Route path="/BehavioralDistribution" component={BehavioralDistribution} />
        <Route path="/Socio-Demographic-Distribution" component={SocioDemographic} />
        <Route path="/user/:userID" component={DetailsPage} />

    </Security>
  );
};

const AppWithRouterAccess = withRouter(App);

const RouterApp = () => {
  return (
    <Router>
      <AppWithRouterAccess />
    </Router>
  );
};

export default RouterApp;
