import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";


const Getusers = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      setUserInfo(authState.idToken.claims);
    }
  }, [authState, oktaAuth]);
  
  
  
  const getNameAbbreviation = (fullName) => {
    if(fullName !== undefined){
      const trimmedName = fullName.trim();
      const nameParts = trimmedName.split(' ').filter(part => part.length > 0);
  
      if (nameParts.length >= 2) {
        const firstName = nameParts[0];
      const firstLetter = firstName.charAt(0);
      const lastName = nameParts[nameParts.length - 1];
      const lastLetter = lastName.charAt(0); // Fix here
      return `${firstLetter}${lastLetter}`.toUpperCase();
      }
  
      return '';
  
    }
  };

  const getFirstName = (fullName) => {
    if(fullName !== undefined){
      const trimmedName = fullName.trim();
      const nameParts = trimmedName.split(' ').filter(part => part.length > 0);
  
      if (nameParts.length >= 2) {
        const firstName = nameParts[0];
      const firstLetter = firstName;
      return `${firstLetter}`
      }
  
      return '';
  
    }
  };




  return (
    <> 
      {props?.name && <span style={{ display: "grid", gridGap: "5px",justifyContent:"center" }} className={`userinfo colorCss ${props.className}`}>{userInfo?.name}</span>}
      {props?.email && <span style={{ display: "grid", gridGap: "5px",justifyContent:"center" }} className={`userinfo colorCss ${props.className}`}>{userInfo?.email}</span>}
      {props?.trim && <span style={{ display: "grid", gridGap: "5px",justifyContent:"center" }} className={`userinfo colorCss ${props.className}`}>{getNameAbbreviation(userInfo?.name)}</span>}
      {/* {props?.trim && <span style={{ display: "grid", gridGap: "5px",justifyContent:"center" }} className={`userinfo colorCss ${props.className}`}>RN</span>} */}


      {props?.firstName && <span style={{ display: "grid", gridGap: "5px",justifyContent:"center" }} className={`userinfo colorCss ${props.className}`}>{getFirstName(userInfo?.name)}</span>}

    </>
  );
}

export default Getusers
