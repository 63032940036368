import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const SingleHorizontalBarChats = ({category,value,type,color}) => {
  // Define the initial state using useState hook
  const [chartState] = useState({
    series: [
      {
        name: `${type}`,
        data: value,
      }
    ],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar:{
          show:false
        }
      },
      colors: [`${color}`],
      plotOptions: {
        bar: {
          borderRadius: 5,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'all', // 'all', 'last'
          horizontal: true,
          barHeight: '50%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        stepSize: 1,
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val) {
            return val;
          },
        },
        y: {
          formatter: function (val) {
            return Math.abs(val) + '%'; // Display in millions
          },
        },
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: category || [],
        stepSize: 20,
        min: 0, // Set the minimum value for the x-axis
        max: 100,  // Set the maximum value for the x-axis
        title: {
          text: '',
        },
        labels: {
          formatter: function (val) {
            return Math.abs(Math.round(val)) + '%' ;
          },
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="bar"
          height={380}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default SingleHorizontalBarChats;
