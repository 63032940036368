import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ColumnwithMarkers = ({data}) => {

 const top10Data = [...data]
 .sort((a, b) => b.y - a.y) // Sorting in descending order
 .slice(0, 10); // Take the top 5 entries

 console.log(top10Data)

  const [chartData] = useState({
    series: [
      {
        name: 'Interested in watch and jewelry',
        data: top10Data // Extract the "interested" values for the top 5
      }
    ],
    options: {
      chart: {
        type: 'bar',
        toolbar:{
            show:false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '60%'
        }
      },
      colors: ['#045858', '#F7B844'], // Changed purple to white
        dataLabels: {
        enabled: false
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Interested in watch and jewelry','Overall'],
        markers: {
          fillColors: ['#045858', '#F7B844'] // Update legend colors as well
        }
      }
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={380} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ColumnwithMarkers;
