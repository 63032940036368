import React, { useState } from "react";
import CitizenData from "../../data/citizen.json";
import { useParams } from "react-router-dom";
import SideNavBar from "../../layout/Nav/Sidebar";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import './DetailsPage.scss';

const DetailsPage = () => {
  const [dataitem, setDataItem] = useState(CitizenData);

  const { userID } = useParams();
  ////console.log('URL parameter:', userID);

  const userDetails = dataitem && dataitem.find((item) => item.user === `${userID}`);
  ////console.log('User Details:', userDetails);

  const excludeColumns = ["State", "Country", "LocationCountryCode","LocationCode","CountryCode","TypeOfCruiseTripsCategory","TypeOfAgeCategory"];


  return (
    <div>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <SideNavBar />
        <Box className="mainCls" component="main" sx={{ flexGrow: 1, p: 1 }}>
          <div id="mainHome">
            <span className="headerTextNewMain">Details Summary</span>
            <div id="DetailsPage">
              {userDetails ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{width:"25%"}} className=""><div className=""><strong>Name</strong></div></TableCell>
                        <TableCell><strong>User Informations</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(userDetails)
                       .filter(([key]) => !excludeColumns.includes(key)) // Filter out excluded columns
                      .map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell className="keyandvalue">{key}</TableCell>
                          <TableCell>
                            {value !== null && value !== undefined && value !== '' ? 
                              (typeof value === 'object' ? JSON.stringify(value) : value) 
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <p>User not found</p>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default DetailsPage;
