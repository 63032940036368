import React from "react";
import { VectorMap } from "react-jvectormap";
import './MapChart.scss';
import { Divider } from "@mui/material";

const mapData = {
  "US-AB": 90,   // Oklahoma
  "US-NS": 90,   // Oklahoma


};

// const statesData = [
//   { name: "Florida", progress: 2 },
//   { name: "North Florida", progress: 2 },
//   { name: "Oklahoma", progress: 2 },
//   { name: "Texas (Pearland)", progress: 1 },
//   { name: "Outside Orlando, FL", progress: 2 },
//   { name: "Eastern PA", progress: 2 },
//   { name: "Connecticut (Enfield)", progress: 2 },
//   { name: "Jacksonville, FL", progress: 2 },
//   { name: "New Jersey", progress: 2 },
//   { name: "North Carolina (Charlotte)", progress: 2 },
//   { name: "South Carolina", progress: 2 },
//   { name: "New York", progress: 110 },
//   { name: "Chicago", progress: 80 },
//   { name: "Calgary / Malaga", progress: 50 },
//   { name: "Pflugerville, TX", progress: 40 },
//   { name: "Memphis, TN", progress: 65 },
//   { name: "Kansas City, MO", progress: 55 },
//   { name: "Orlando, FL", progress: 90 },
//   { name: "Rhode Island (Little Rhody)", progress: 45 },
//   { name: "College Station, TX", progress: 30 },
//   { name: "Woodland, California", progress: 85 },
//   { name: "Kentucky", progress: 50 },
//   { name: "Mexico", progress: 100 },
//   { name: "Manassas, VA", progress: 80 },
//   { name: "Fayetteville, NC", progress: 75 },
//   { name: "Grand Ledge, MI", progress: 60 },
//   { name: "San Diego, CA", progress: 95 },
//   { name: "Halifax, NS", progress: 50 },
// ];

const VectorMapBox = React.memo(({data,category}) => {
category.sort((a, b) => b.progress - a.progress);
  return (
    <div style={{ backgroundColor: "white",padding:"15px" }}>
      <div className="MapBoxVector" style={{ width: "100%", height: "400px", display: "flex", gap: "10px" }}>
        <VectorMap
          map={"us_aea"} // Use a US-specific map
          backgroundColor="#FFFFFF"
          className="MapBoxVector1"
          containerStyle={{
            width: "65%",
            height: "100%",
          }}
          regionStyle={{
            initial: {
              fill: "#E3E3E3",
            },
            hover: {
              fill: "#C8EEFF",
            },
            selected: {
              fill: "#0071A4",
            },
          }}
          series={{
            regions: [
              {
                values: data,
                scale: ["#7E6E99", "#045858",'#C3A5D0',"#069098"], // Change the scale to a visible range
                normalizeFunction: "polynomial",
              },
            ],
          }}
          onRegionTipShow={(e, el, code) => {
            ////console.log('Region Code:', code); // Debugging output
            const value = data[code]; // Adjust the code lookup
            if (value !== undefined) {
              el.html(`${code} (Value: ${value})`);
            } else {
              el.html(`${code} 0`); // Fallback if no data found
            }
          }}
        />

        <div id="MapState" style={{ width: "35%", backgroundColor: "#FFFFFF", paddingRight: "10px", position: "relative", height: "400px", overflow: "hidden",overflowY:"auto" }}>
          <div style={{display:"flex",justifyContent:"space-between",fontWeight:"600",paddingBottom:"10px",position:"sticky",top:"0",backgroundColor:"white"}}>
            <span style={{fontWeight:"700"}}>State</span>
            <span style={{fontWeight:"700"}}>%</span>

          </div>
          <Divider/>
          {category && category.map((state) => (
            <div key={state.name} className="progress-bar-container">
          <div style={{display:"flex",justifyContent:"space-between",fontWeight:"600",paddingBottom:"2px"}}>
          <div className="progress-label">{state.name}</div>
              <div className="progress-label">{state.progress}%</div>

              </div>
              <div title={(state.progress / 100) * 100} className="progress-bar">
                <div
                  className="progress-fill"
                  style={{ width: `${(state.progress / 100) * 100}%` }} // Adjusted for progress
                  title={(state.progress / 100) * 100}
                ></div>
              </div>
            
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default VectorMapBox;
