import React from 'react';
import ReactApexChart from 'react-apexcharts';

const RangeBarChart = ({ dataSet,MaxValue,category }) => {
  const options = {
    series: [
      {
        name: '', // Optional: Name for the series
        data: dataSet,
      },
    ],
    chart: {
      type: 'rangeBar', // Ensure you are using 'rangeBar' for the type
      zoom: { enabled: false },
      toolbar:{
        show:false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true, // Set this to true for a horizontal bar chart
        isDumbbell: true,
        columnWidth: '50%', // Adjust width as needed
        dumbbellColors: [['#045858', '#C3A5D0']],
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val + '%';
        },
      },
   
     
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      customLegendItems: category,
      markers: {
        fillColors: ['#045858', '#C3A5D0'], // Legend colors
      },

    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        gradientToColors: ['#C3A5D0'],
        inverseColors: true,
        stops: [0, 100],
      },
    },
    grid: {
      xaxis: {
        lines: { show: true },
      },
      yaxis: {
        lines: { show: false },
      },
    },
    yaxis: {
      tickPlacement: 'on',
    },
    xaxis: {
      min: 0, // Minimum value of x-axis
      max:100, // Maximum value of x-axis
      tickAmount:5, // Number of ticks (steps between ticks)
      title: {
        text: '',
      },
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val)) + '%' ;
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart 
        options={options} 
        series={options.series} 
        type="rangeBar" 
        height={350}
      />
    </div>
  );
};

export default RangeBarChart;
