import React, { useState } from "react";
import SideNavBar from "../../layout/Nav/Sidebar";
import { Box } from "@mui/material";
import "./SocioDemographic.scss";
import NewMap from "../../layout/charts/Map/VectorMapBox";
import CitizenData from "../../data/citizen.json";
import SingleHorizontalBarChats from "../../layout/charts/Bar/SingleHorizontalBarChats";
import circleIcon1 from "../../assets/imgs/icon1.svg";
import circleIcon2 from "../../assets/imgs/icon2.svg";
import circleIcon3 from "../../assets/imgs/Group1362.svg";
import HeaderImg1 from "../../assets/imgs/new-Vector-3.svg";
import HeaderImg2 from "../../assets/imgs/socio-2nd-icon.svg";
import HeaderImg3 from "../../assets/imgs/socio-3rd-icon.svg";
import HeaderImg4 from "../../assets/imgs/socio-4th-icon.svg";
import ExportToExcel from "../../layout/Export/Export";

const SocioDemographic = () => {
  const [data, setData] = useState(CitizenData);
  const desiredOrder = ["Less then 45", "45 to 55", "55 to 60", "60 above"];
  const desiredOrdercruisetipes = [
    "Less Than 10",
    "10 to 20",
    "20 to 30",
    "More than 30",
  ];

  const TypeOfAgeCategory = data
    ? data
        .map((item) => item.TypeOfAgeCategory) // Extract the TypeOfAgeCategory
        .filter((category) => category !== undefined) // Remove undefined values
    : [];
  // const uniqueTypeOfAgeCategory = [...new Set(TypeOfAgeCategory)];

  ////console.log("TypeOfAgeCategory")
  ////console.log(TypeOfAgeCategory)

  const uniqueTypeOfAgeCategory = [...new Set(TypeOfAgeCategory)]
  .filter(category => category !== 'Not Stated') // Remove empty strings
  .sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b))
  .reverse();

    // //console.log(uniqueTypeOfAgeCategory)

    const TotalProfiles = data
    ? data.filter((item) => item["Profile URL"]).length
    : 0;

    // ////console.log("TotalProfiles")
    // ////console.log(TotalProfiles)

    const countOfmaleTypeOfAgeCategory = data
    ? data
        .filter((item) => item.Gender === "Male") // Filter for Male gender
        .map((item) => item.TypeOfAgeCategory) // Extract the TypeOfAgeCategory for Males
        .filter((category) => category) // Remove undefined values and empty strings
        .reduce((acc, category) => {
            acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
            return acc;
        }, {})
    : {};

    
  // Extract and count TypeOfAgeCategory for Females
  const countOfFemaleTypeOfAgeCategory = data
    ? data
        .filter((item) => item.Gender === "Female") // Filter for Female gender
        .map((item) => item.TypeOfAgeCategory) // Extract the TypeOfAgeCategory for Females
        .filter((category) => category !== '') // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};





  // const maxValueFemale = Math.max(...femaleTypeOfAgeCategoryArray);

  // const overallMaxValue = Math.max(maxValueMale, maxValueFemale);

  const typeOfCruiseTripsCategory = data
    ? data
        .map((item) => item.TypeOfCruiseTripsCategory) // Extract the TypeOfAgeCategory
        .filter((category) => category !== undefined) // Remove undefined values
    : [];

  // const uniqueTypeOfCruiseTripsCategory = [
  //   ...new Set(typeOfCruiseTripsCategory).sort(
  //     (a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b)
  // ];

  const uniqueTypeOfCruiseTripsCategory = [
    ...new Set(typeOfCruiseTripsCategory),
  ]
    .sort(
      (a, b) =>
        desiredOrdercruisetipes.indexOf(a) - desiredOrdercruisetipes.indexOf(b)
    )
    .reverse();


    console.log("uniqueTypeOfCruiseTripsCategory",uniqueTypeOfCruiseTripsCategory)


  const TypeOfCruiseTripsCategorycount = typeOfCruiseTripsCategory.reduce(
    (acc, category) => {
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    },
    {}
  );

  // //console.log(TypeOfCruiseTripsCategorycount)

  const TypeOfCruiseTripsCategoryCount = uniqueTypeOfCruiseTripsCategory.map(
    (category) => (TypeOfCruiseTripsCategorycount[category]/TotalProfiles * 100).toFixed(0) || 0
  );

  // //console.log("uniqueTypeOfCruiseTripsCategory")
  // //console.log(TypeOfCruiseTripsCategorycount)
  

  const MaritalStatusCategory = data
    ? data
        .map((item) => item["Marital Status"]) // Extract the Marital Status
        .filter((category) => category) // Remove undefined values
    : [];

  const lenthOfMaritalStatusCategory = MaritalStatusCategory.length || 1;

  ////console.log("lenthOfMaritalStatusCategory",lenthOfMaritalStatusCategory)
  // Log the Marital Status categories

  const Alone = data
    ? data
        .filter((item) => item["Marital Status"] === "Single") // Filter for Married
        .map((item) => item["Marital Status"]) // Extract the Marital Status
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  // Log the result



  const MarridWithwifeandKid = data
    ? data
        .filter(
          (item) => item["Marital Status"] === "Married" && item.Kids === "Yes"
        ) // Filter for Married
        .map((item) => item["Marital Status"]) // Extract the Marital Status
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  // Log the result

  const MarridWithWifewithNokid = data
    ? data
        .filter(
          (item) => item["Marital Status"] === "Married" && item.Kids === "No"
        ) // Filter for Married
        .map((item) => item["Marital Status"]) // Extract the Marital Status
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};


    //console.log("Alone",Alone)
    //console.log("Family",MarridWithwifeandKid)
    //console.log("Copule",MarridWithWifewithNokid)

  const countofcountrycode = data
    ? data
        .filter((item) => item.LocationCode) // Filter for items with LocationCode
        .map((item) => `US-${item.LocationCode}`) // Prefix LocationCode with "US-"
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0)  + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

 

  const countofcountrycode1 = data
    ? data
        .filter((item) => item.LocationCode) // Filter for items with LocationCode
        .map((item) => item.LocationCode) // Prefix LocationCode with "US-"
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

    const countofcountrycode2 = data
    ? data
        .filter((item) => item.State) // Filter for items with LocationCode
        .map((item) => item.State) // Prefix LocationCode with "US-"
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  // const formattedOutput = Object.entries(countofcountrycode)
  // .map(([key, value]) => `"${key}": ${value}`) // Add quotes around keys
  // .join(", "); // Join with a comma and space

 

  const TotalMale = data
    ? data.filter((item) => item.Gender === "Male").length
    : 0;

    
  const TotalFemale = data
    ? data.filter((item) => item.Gender === "Female").length
    : 0;
   

  const Totalcountry = data
    ? data.filter((item) => item.LocationCode !== undefined).length
    : 0;

  const TotalNothState = TotalProfiles - TotalMale - TotalFemale;

  const formattedStateData = Object.entries(countofcountrycode1).map(
    ([key, value]) => {
      const locationCode = key; // Extract the LocationCode (e.g., "FL")
      const foundItem = data.find((item) => item.LocationCode === locationCode);
      const stateName = foundItem ? foundItem.StateName : "Unknown"; // Adjust based on your actual property name
      return {
        name: locationCode, // State name
        progress: ((value / Totalcountry) * 100).toFixed(0), // Occurrences count
      };
    }
  );
  const formattedStateData1 = Object.entries(countofcountrycode2).map(
    ([key, value]) => {
      const locationCode = key; // Extract the LocationCode (e.g., "FL")
      const foundItem = data.find((item) => item.State === locationCode);
      const stateName = foundItem ? foundItem.StateName : "Unknown"; // Adjust based on your actual property name
      return {

        name: locationCode, // State name
        progress: ((value / Totalcountry) * 100).toFixed(0), // Occurrences count
      };
    }
  );

  // console.log("formattedStateData",formattedStateData1)
  // console.log("countofcountrycode1",countofcountrycode2)

  const percentageOfCountryCode = Object.keys(countofcountrycode).reduce((acc, key) => {
    acc[key] = ((countofcountrycode[key] / Totalcountry) * 100).toFixed(0);
    return acc;
  }, {});



  const profileData = [
    (parseInt(Alone.Alone) /
    TotalProfiles) *
    100,
    (parseInt(MarridWithWifewithNokid.Married) /
    TotalProfiles) *
    100,
    (parseInt(MarridWithwifeandKid.Married) /
    TotalProfiles) *
    100
    //  100 -  (parseInt(Alone.Alone) /
    // TotalProfiles) *
    // 100 -  (parseInt(MarridWithWifewithNokid.Married) /
    // TotalProfiles) *
    // 100 -  (parseInt(MarridWithwifeandKid.Married) /
    // TotalProfiles) *
    // 100
  ]

  const TotalMaleProfile = TotalMale ;

  //////console.log("TotalMaleProfile")
  //////console.log(TotalMaleProfile)

  const TotalFeMaleProfile = ((TotalFemale / TotalProfiles) * 100).toFixed(0)

  //////console.log("TotalFeMaleProfile")
  //////console.log(TotalFemale)

 


  //console.log(countOfmaleTypeOfAgeCategory)
  //console.log(countOfFemaleTypeOfAgeCategory)

// Subtract the count of "Not Stated" from the total male count
const totalMaleAdjusted = TotalMale - countOfmaleTypeOfAgeCategory['Not Stated'];
const totalFeMaleAdjusted = TotalFemale - countOfFemaleTypeOfAgeCategory['Not Stated'];

  // const findmaleTypeOfAgeCategoryArray = uniqueTypeOfAgeCategory.map(
  //   (category) => countOfmaleTypeOfAgeCategory[category] || 0
  // );

  const maleTypeOfAgeCategoryArray = uniqueTypeOfAgeCategory.map(
    (category) => (countOfmaleTypeOfAgeCategory[category]/totalMaleAdjusted * 100).toFixed(0) || 0
  );
  // const maxValueMale = Math.max(...findmaleTypeOfAgeCategoryArray);

  // Convert female count object to array with all categories
  const femaleTypeOfAgeCategoryArray = uniqueTypeOfAgeCategory.map(
    (category) => (countOfFemaleTypeOfAgeCategory[category]/totalFeMaleAdjusted * 100).toFixed(0) || 0
  );

  // //console.log("TypeOfCruiseTripsCategoryCount",TypeOfCruiseTripsCategoryCount)
  // //console.log("uniqueTypeOfCruiseTripsCategory",uniqueTypeOfCruiseTripsCategory)

  //console.log("countOfFemaleTypeOfAgeCategory",countOfFemaleTypeOfAgeCategory)


  // const singleCruise = data && data.map((item) => item['Cruising Companion'] === 'Single').length

  const singleCruise = data
  ? data.filter((item) => item['Cruising Companion'] === 'Solo').length
  : 0;

  const copuleCruise = data
  ? data.filter((item) => item['Cruising Companion'] === 'Couple').length
  : 0;

  const FamilyCruise = data
  ? data.filter((item) => item['Cruising Companion'] === 'Family').length
  : 0;
  console.log("singleCruise",singleCruise)
  console.log("copuleCruise",copuleCruise)
  console.log("FamilyCruise",FamilyCruise)
  console.log("lenthOfMaritalStatusCategory",lenthOfMaritalStatusCategory)
  console.log("TotalProfiles",TotalProfiles)



  return (
    <div>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <SideNavBar />

        <Box className="mainCls" component="main" sx={{ flexGrow: 1, p: 1 }}>
          <div id="mainHome">
            <span className="headerTextNewSoico">
               Demographic Analysis
            </span>
            {/* <ExportToExcel jsonData={data} fileName="UserData" /> */}

            <div className="mainSocioDemographic">
              <div
                style={{
                  display: "grid",
                  gap: "30px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <div className="marginBox">
                    <div className="firstBox4">
                      {/* <div  className="MainSection">
                           <div style={{display:"grid",padding:"1rem",textAlign:"left",justifyContent:"flex-start",gap:"5px",paddingTop:"2rem"}}>
                              <span style={{fontSize:"20px",color:"white"}}>Welcome Back</span>
                              <span  style={{fontSize:"36px",color:"white",textAlign:"left"}}><Getusers name={true} className="HomeText"/></span>
                              <span style={{color:"white"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </span>

                           </div>
                      </div> */}
                      <div className="box-item">
                        <span className="imgCls">
                          <img  src={HeaderImg1} alt="img" />
                        </span>
                        <div style={{ display: "",lineHeight:"1px" }}>
                          <span className="card-title card-title-1">
                            Number of <b>Profiles</b> 
                          </span>
                         
                        </div>

                        <span className="card-values">{TotalProfiles}</span>
                      </div>
                      <div className="box-item">
                        <span className="imgCls">
                          <img src={HeaderImg2} alt="img" />
                        </span>
                        <div style={{ display: "grid" }}>
                          <span className="card-title card-title-1">
                          % <b>Male </b>
                          </span>
                          
                        </div>

                        <span className="card-values">
                          {((TotalMale / TotalProfiles) * 100).toFixed(0)}%
                        </span>
                      </div>
                      <div className="box-item">
                        <span className="imgCls">
                          <img src={HeaderImg3} alt="img" />
                        </span>
                        <div style={{ display: "grid" }}>
                          <span className="card-title card-title-1">
                          % <b>Female</b>
                          </span>
                         
                        </div>

                        <span className="card-values">
                          {((TotalFemale / TotalProfiles) * 100).toFixed(0)}%
                        </span>
                      </div>
                      <div className="box-item">
                        <span className="imgCls">
                          <img src={HeaderImg4} alt="img" />
                        </span>
                        <div style={{ display: "grid" }}>
                          <span className="card-title card-title-1">
                          % <b>Not stated</b>{" "}
                          </span>
                          
                        </div>

                        <span className="card-values">
                          {((TotalNothState / TotalProfiles) * 100).toFixed(0)}%
                        </span>
                      </div>

                      <div style={{backgroundColor:"transparent"}} className="box-item">
                       
                      </div>
                      {/* <div className="my-element12">
                        {<span
                          style={{
                            color: "#fff",
                            fontWeight: "600",
                            paddingBottom: "10px",
                          }}
                        >
                          Cruising Companion
                        </span>
                        <div className="my-element">
                          <div
                            style={{
                              display: "grid",
                              gap: "10px",
                              justifyContent: "center",
                              justifyItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <div className="my-element-1 ">
                              <img width="50px" src={circleIcon1} alt="1" />
                            </div>
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                justifyItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{ fontWeight: "600", color: "white" }}
                              >
                                Solo
                              </span>
                              <span
                                style={{ fontWeight: "600", color: "white",fontSize:"24px" }}
                              >
                                {(parseInt(Alone.Alone) /
                                  lenthOfMaritalStatusCategory) *
                                  100}
                                %
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "grid",
                              gap: "10px",
                              justifyContent: "center",
                              justifyItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <div className="my-element-1 ">
                              <img width="50px" src={circleIcon2} alt="1" />{" "}
                            </div>
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                justifyItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{ fontWeight: "600", color: "white" }}
                              >
                                 Couple
                              </span>
                              <span
                                style={{ fontWeight: "600", color: "white",fontSize:"24px" }}
                              >
                                {(MarridWithWifewithNokid.Married /
                                  lenthOfMaritalStatusCategory) *
                                  100}
                                %
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "grid",
                              gap: "10px",
                              justifyContent: "center",
                              justifyItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <div className="my-element-1 ">
                              <img width="50px" src={circleIcon3} alt="1" />{" "}
                            </div>
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                justifyItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{ fontWeight: "600", color: "white" }}
                              >
                                 Family
                              </span>
                              <span
                                style={{ fontWeight: "600", color: "white",fontSize:"24px" }}
                              >
                                {(MarridWithwifeandKid.Married /
                                  lenthOfMaritalStatusCategory) *
                                  100}
                                %
                              </span>
                            </div>
                          </div>
                        </div> }
                      </div> */}
                    </div>
                  </div>


               

                  <div style={{marginTop:"20px"}} className="marginBox">
                   
                    <div className="firstBox1">
                      <span
                        style={{
                          background: "white",
                          display: "grid",
                          alignContent: "center",
                          alignItems: "center",
                          borderRadius: "20px",
                          padding:"10px"
                        }}
                        className="item1"
                      >
                        {/* <HorizontalBarChart
                          category={uniqueTypeOfAgeCategory}
                          maleData={maleTypeOfAgeCategoryArray}
                          femaleDate={femaleTypeOfAgeCategoryArray}
                          maxvalue={overallMaxValue}
                        /> */}
                         <span
                        style={{
                          display: "block",
                          fontWeight: "700",
                          textAlign: "left",
                          paddingLeft: "20px",
                          fontSize: "20px",
                          padding:"10px"

                        }}
                        className="item1"
                      >
                        Age distribution of male
                      </span>
                        <SingleHorizontalBarChats
                          category={uniqueTypeOfAgeCategory}
                          value={maleTypeOfAgeCategoryArray}
                          type="Male"
                          color="#C3A5D0"

                        />
                      </span>
                      <span
                        style={{
                          background: "white",
                          display: "grid",
                          alignContent: "center",
                          alignItems: "center",
                          borderRadius: "20px",
                        }}
                        className="item1"
                      >
                          <span
                        style={{
                          display: "block",
                          fontWeight: "700",
                          textAlign: "left",
                          paddingLeft: "20px",
                          fontSize: "20px",
                          padding:"10px"

                        }}
                        className="item1"
                      >
                        Age distribution of female
                      </span>
                        <SingleHorizontalBarChats
                          category={uniqueTypeOfAgeCategory}
                          value={femaleTypeOfAgeCategoryArray}
                          type="Female"
                          color="#7E6E99"

                        />
                      </span>
                    </div>
                  </div>

                  <div className="marginBox">
                   
                    <div className="firstBox5">
                    
                    <span
                       style={{
                        background: "white",
                        display: "grid",
                        alignContent: "baseline",
                        alignItems: "baseline",
                        gap:"5rem",
                        borderRadius: "20px",
                        padding:"10px"

                      }}
                      className="item1"
                      >
                        
                      <span
                        style={{
                          padding: "1rem",
                          display: "block",
                          fontWeight: "700",
                          textAlign: "left",
                          paddingLeft: "5px",
                          fontSize: "20px",
                        }}
                        className="item1"
                      >
                        Distribution by companionship 
                      </span>

                        {/* <DonutChart total={TotalProfiles} data={profileData}/> */}

                        <div className="my-element">
                          <div
                            style={{
                              display: "grid",
                              gap: "10px",
                              textAlign: "center",
                            }}
                          >
                            <div className="my-element-1 ">
                              <img width="50px" src={circleIcon1} alt="1" />
                            </div>
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                justifyItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Solo
                              </span>
                              <span
                                style={{ fontWeight: "600", color: "black",fontSize:"24px" }}
                              >
                                {((parseInt(singleCruise) /
                                  TotalProfiles) *
                                  100).toFixed(0) - 1}
                                %
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "grid",
                              gap: "10px",
                              justifyContent: "center",
                              justifyItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <div className="my-element-1 ">
                              <img width="50px" src={circleIcon2} alt="1" />{" "}
                            </div>
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                justifyItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                 Couple
                              </span>
                              <span
                                style={{ fontWeight: "600", color: "black",fontSize:"24px" }}
                              >
                                {((copuleCruise /
                                  TotalProfiles) *
                                  100).toFixed(0)}
                                %
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "grid",
                              gap: "10px",
                              justifyContent: "center",
                              justifyItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <div className="my-element-1 ">
                              <img width="50px" src={circleIcon3} alt="1" />{" "}
                            </div>
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                justifyItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                 Family
                              </span>
                              <span
                                style={{ fontWeight: "600", color: "black",fontSize:"24px" }}
                              >
                                {((FamilyCruise /
                                  TotalProfiles) *
                                  100).toFixed(0)}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                    </span>
                    
                      <span
                        style={{
                          background: "white",
                          display: "grid",
                          alignContent: "center",
                          alignItems: "center",
                          borderRadius: "20px",
                          padding:"10px"
                        }}
                        className="item1"
                      >
                        {/* <HorizontalBarChart
                          category={uniqueTypeOfAgeCategory}
                          maleData={maleTypeOfAgeCategoryArray}
                          femaleDate={femaleTypeOfAgeCategoryArray}
                          maxvalue={overallMaxValue}
                        /> */}
                          <span
                        style={{
                          padding: "1rem",
                          display: "block",
                          fontWeight: "700",
                          textAlign: "left",
                          paddingLeft: "20px",
                          paddingBottom: "2rem",
                          fontSize: "20px",
                        }}
                        className="item1"
                      >
                         Distribution by travel frequency 
                      </span>
                        <SingleHorizontalBarChats
                          category={uniqueTypeOfCruiseTripsCategory}
                          value={TypeOfCruiseTripsCategoryCount}
                          femaleDate={femaleTypeOfAgeCategoryArray}
                          color="#045858"
                          type=""

                        />
                      </span>

                   
                    </div>

                   
                  </div>

                  <div className="marginBox">
                   
                    <div className="FullBox">
                      <span
                        style={{
                          background: "white",
                          display: "grid",
                          alignContent: "center",
                          alignItems: "center",
                          padding: "10px",
                          borderRadius: "20px",
                          
                        }}
                      >
                         <span
                      style={{
                        padding: "1rem",
                        display: "block",
                        fontWeight: "700",
                        textAlign: "left",
                        paddingLeft: "20px",
                        paddingBottom: "2rem",
                        fontSize: "20px",
                      }}
                    >
                       Distribution by location
                    </span>
                        <NewMap
                          data={percentageOfCountryCode}
                          category={formattedStateData}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="watermark"></div> */}

          </div>
        </Box>
      </Box>
    </div>
  );
};

export default SocioDemographic;
