import React, { useState } from "react";
import SideNavBar from "../../layout/Nav/Sidebar";
import { Box } from "@mui/material";
import "./Product.scss";
import RangeBarChart from "../../layout/charts/RadialBarChart/RangeBarChart";
import CitizenData from "../../data/citizen.json";
import ApexDountChatNew from "../../layout/charts/Pie/ApexDountChatNew";

const Psychographic = () => {
  const [data, setData] = useState(CitizenData);

  const MusicEnthusiastFlagCount = data
    ? data.filter((item) => item["Music enthusiast Flag"] === "Yes").length
    : 0;

    
    const Excursions = data
    ? data.filter((item) => item.Excursions === "Yes").length
    : 0;

    //console.log(Excursions)
  const TechEnthusiastFlagCount = data
    ? data.filter((item) => item["Tech enthusiast Flag"] === "Yes").length
    : 0;

    const Fashion = data
    ? data.filter((item) => item["Fashion Style Enthusiast"] === "Yes").length
    : 0;

    
    // console.log("Fashion",Fashion)

  const Wellness = data
    ? data.filter(
        (item) =>
          item["Interested in Massage & Spa or wellness retreat Flag"] === "Yes"
      ).length
    : 0;

    const ArtAndCulture = data
    ? data.filter(
        (item) =>
          item["Love for Art and Culture"] === "Yes"
      ).length
    : 0;


  const Sustainability = data
    ? data.filter((item) => item["Values Sustainability Flag"] === "Yes").length
    : 0;

  const Adventure = data
    ? data.filter(
        (item) => item["Adventure Sports / Outdoor Sports enthusiast"] === "Yes"
      ).length
    : 0;


  const Shopping = data
    ? data.filter((item) => item["Shopping Flag"] === "Yes").length
    : 0;



  const MusicEnthusiastFlagCountMale = data
    ? data.filter(
        (item) =>
          item["Music enthusiast Flag"] === "Yes" && item.Gender === "Male"
      ).length
    : 0;

  const MusicEnthusiastFlagCountFeMale = data
    ? data.filter(
        (item) =>
          item["Music enthusiast Flag"] === "Yes" && item.Gender === "Female"
      ).length
    : 0;

  const TechEnthusiastFlagCountMale = data
    ? data.filter(
        (item) =>
          item["Tech enthusiast Flag"] === "Yes" && item.Gender === "Male"
      ).length
    : 0;

    const ExcursionsEnthusiastFlagCountMale = data
    ? data.filter(
        (item) =>
          item.Excursions === "Yes" && item.Gender === "Male"
      ).length
    : 0;

    const ExcursionsEnthusiastFlagCountFemale = data
    ? data.filter(
        (item) =>
          item.Excursions === "Yes" && item.Gender === "Female"
      ).length
    : 0;
    

  const TechEnthusiastFlagCountFeMale = data
    ? data.filter(
        (item) =>
          item["Tech enthusiast Flag"] === "Yes" && item.Gender === "Female"
      ).length
    : 0;

  const WellnessMale = data
    ? data.filter(
        (item) =>
          item["Interested in Massage & Spa or wellness retreat Flag"] ===
            "Yes" && item.Gender === "Male"
      ).length
    : 0;

  const WellnessFemale = data
    ? data.filter(
        (item) =>
          item["Interested in Massage & Spa or wellness retreat Flag"] ===
            "Yes" && item.Gender === "Female"
      ).length
    : 0;

  const SustainabilityMale = data
    ? data.filter(
        (item) =>
          item["Values Sustainability Flag"] === "Yes" && item.Gender === "Male"
      ).length
    : 0;

  const SustainabilityFemale = data
    ? data.filter(
        (item) =>
          item["Values Sustainability Flag"] === "Yes" &&
          item.Gender === "Female"
      ).length
    : 0;

  const AdventureMale = data
    ? data.filter(
        (item) =>
          item["Adventure Sports / Outdoor Sports enthusiast"] === "Yes" &&
          item.Gender === "Male"
      ).length
    : 0;

  const AdventureFemale = data
    ? data.filter(
        (item) =>
          item["Adventure Sports / Outdoor Sports enthusiast"] === "Yes" &&
          item.Gender === "Female"
      ).length
    : 0;

  const ShoppingMale = data
    ? data.filter(
        (item) => item["Shopping Flag"] === "Yes" && item.Gender === "Male"
      ).length
    : 0;

  const ShoppingFemale = data
    ? data.filter(
        (item) => item["Shopping Flag"] === "Yes" && item.Gender === "Female"
      ).length
    : 0;
  const totalMale = data
    ? data.filter((item) => item.Gender === "Male").length
    : 0;

  const totalFemale = data
    ? data.filter((item) => item.Gender === "Female").length
    : 0;
  const total = totalMale + totalFemale;
  ////console.log("MusicEnthusiastFlagCountFeMale");
  ////console.log(MusicEnthusiastFlagCountFeMale);

  ////console.log(totalMale);
  ////console.log(totalFemale);

  const dataSet = [
    {
      x: "Excursions",
      y: [
        (ExcursionsEnthusiastFlagCountMale / totalMale) * 100,
        (ExcursionsEnthusiastFlagCountFemale / totalFemale) * 100,
      ],
    },
    {
      x: "Shopping",
      y: [
        (ShoppingMale / totalMale) * 100,
        (ShoppingFemale / totalFemale) * 100,
      ],
    },
    {
      x: "Adventure Sports",
      y: [
        (AdventureMale / totalMale) * 100,
        (AdventureFemale / totalFemale) * 100,
      ],
    },
    {
      x: "Music",
      y: [
        (MusicEnthusiastFlagCountMale / totalMale) * 100,
        (MusicEnthusiastFlagCountFeMale / totalFemale) * 100,
      ],
    },
    {
      x: "Wellness / Spa",
      y: [
        (WellnessMale / totalMale) * 100,
        (WellnessFemale / totalFemale) * 100,
      ],
    },
    {
      x: "Fashion",
      y: [
        (TechEnthusiastFlagCountMale / totalMale) * 100,
        (TechEnthusiastFlagCountFeMale / totalFemale) * 100,
      ],
    },
    {
      x: "Tech",
      y: [
        (TechEnthusiastFlagCountMale / totalMale) * 100,
        (TechEnthusiastFlagCountFeMale / totalFemale) * 100,
      ],
    },
    
    {
      x: "Sustainability",
      y: [
        (SustainabilityMale / totalMale) * 100,
        (SustainabilityFemale / totalFemale) * 100,
      ],
    },
    
    
   
  ];

  const highestValue = Math.max(
    MusicEnthusiastFlagCountMale,
    MusicEnthusiastFlagCountFeMale,
    TechEnthusiastFlagCountMale,
    TechEnthusiastFlagCountFeMale,
    WellnessMale,
    WellnessFemale,
    SustainabilityMale,
    SustainabilityFemale,
    AdventureMale,
    AdventureFemale,
    ShoppingMale,
    ShoppingFemale
  );

  const maleFemaleCategory = ["Male", "Female"];
  const lessthenGreaterThen = ["Less Than 45", "Greater than 45"];

  const MusicLessthen45 = data
    ? data.filter(
        (item) =>
          item["Music enthusiast Flag"] === "Yes" &&
          item.TypeOfAgeCategory === "Less than 45"
      ).length
    : 0;


  const MusicGreaterthen45 = data
    ? data.filter(
        (item) =>
          item["Music enthusiast Flag"] === "Yes" &&
          item.TypeOfAgeCategory !== "Less than 45"
      ).length
    : 0;

  const TechLessthen45 = data
    ? data.filter(
        (item) =>
          item["Tech enthusiast Flag"] === "Yes" &&
          item.TypeOfAgeCategory === "Less than 45"
      ).length
    : 0;

    const ExcursionsLessthen45 = data
    ? data.filter(
        (item) =>
          item.Excursions === "Yes" &&
          item.TypeOfAgeCategory === "Less than 45"
      ).length
    : 0;

    const ExcursionsGreaterthen45 = data
    ? data.filter(
        (item) =>
          item.Excursions === "Yes" &&
          item.TypeOfAgeCategory !== "Less than 45"
      ).length
    : 0;


    

    



  const TechGreaterthen45 = data
    ? data.filter(
        (item) =>
          item["Tech enthusiast Flag"] === "Yes" &&
          item.TypeOfAgeCategory !== "Less than 45"
      ).length
    : 0;

  const WellnessLessthen45 = data
    ? data.filter(
        (item) =>
          item["Interested in Massage & Spa or wellness retreat Flag"] ===
            "Yes" && item.TypeOfAgeCategory === "Less than 45"
      ).length
    : 0;

  const WellnessGreaterthen45 = data
    ? data.filter(
        (item) =>
          item["Interested in Massage & Spa or wellness retreat Flag"] ===
            "Yes" && item.TypeOfAgeCategory !== "Less than 45"
      ).length
    : 0;

  const SustanabilityLessthen45 = data
    ? data.filter(
        (item) =>
          item["Values Sustainability Flag"] === "Yes" &&
          item.TypeOfAgeCategory === "Less than 45"
      ).length
    : 0;

  const SustanabilityGreaterthen45 = data
    ? data.filter(
        (item) =>
          item["Values Sustainability Flag"] === "Yes" &&
          item.TypeOfAgeCategory !== "Less than 45"
      ).length
    : 0;

  const SportsLessthen45 = data
    ? data.filter(
        (item) =>
          item["Adventure Sports / Outdoor Sports enthusiast"] === "Yes" &&
          item.TypeOfAgeCategory === "Less than 45"
      ).length
    : 0;

  const SportsGreaterthen45 = data
    ? data.filter(
        (item) =>
          item["Adventure Sports / Outdoor Sports enthusiast"] === "Yes" &&
          item.TypeOfAgeCategory !== "Less than 45"
      ).length
    : 0;

  const ShoppingLessthen45 = data
    ? data.filter(
        (item) =>
          item["Shopping Flag"] === "Yes" &&
          item.TypeOfAgeCategory === "Less than 45"
      ).length
    : 0;

  const ShoppingGreaterthen45 = data
    ? data.filter(
        (item) =>
          item["Shopping Flag"] === "Yes" &&
          item.TypeOfAgeCategory !== "Less than 45"
      ).length
    : 0;

  const dataLessThen45 = data
    ? data.filter((item) => item.TypeOfAgeCategory === "Less than 45").length
    : 0;

  const dataGreterThen45 = data
    ? data.filter((item) => item.TypeOfAgeCategory !== "Less than 45").length -
      1
    : 0;

  ////console.log("dataGreterThen45");
  ////console.log(dataGreterThen45);

  ////console.log("dataLessThen45");
  ////console.log(dataLessThen45);

  const dataSetRange = [
    {
      x: "Excursions",
      y: [
        (ExcursionsLessthen45 / dataLessThen45) * 100,
        (ExcursionsGreaterthen45 / dataGreterThen45) * 100,
      ],
    },
    {
      x: "Shopping",
      y: [
        (ShoppingLessthen45 / dataLessThen45) * 100,
        (ShoppingGreaterthen45 / dataGreterThen45) * 100,
      ],
    },
    {
      x: "Adventure Sports",
      y: [
        (SportsLessthen45 / dataLessThen45) * 100,
        (SportsGreaterthen45 / dataGreterThen45) * 100,
      ],
    },
    {
      x: "Music",
      y: [
        (MusicLessthen45 / dataLessThen45) * 100,
        (MusicGreaterthen45 / dataGreterThen45) * 100,
      ],
    },
    {
      x: "Wellness / Spa",
      y: [
        (WellnessLessthen45 / dataLessThen45) * 100,
        (WellnessGreaterthen45 / dataGreterThen45) * 100,
      ],
    },
    {
      x: "Fashion",
      y: [
        (TechLessthen45 / dataLessThen45) * 100,
        (TechGreaterthen45 / dataGreterThen45) * 100,
      ],
    },
    {
      x: "Tech",
      y: [
        (TechLessthen45 / dataLessThen45) * 100,
        (TechGreaterthen45 / dataGreterThen45) * 100,
      ],
    },
    
    {
      x: "Sustainability",
      y: [
       ( SustanabilityLessthen45  / dataLessThen45 )* 100,
        (SustanabilityGreaterthen45 / dataGreterThen45) * 100,
      ],
    }
   
   
   
   
  ];



  const TotalProfiles = data
  ? data.filter((item) => item["Profile URL"]).length
  : 0;


  return (
    <div>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <SideNavBar />

        <Box className="mainCls" component="main" sx={{ flexGrow: 1, p: 1 }}>
          <div id="mainHome">
            <span className="headerTextNew">Psychographic Analysis</span>

            <div className="mainProductPsych">
              <div className="marginBox">
                <div className="firstBox3">
                  <>
                    <span
                      style={{
                        background: "white",
                        padding: "10px",
                        borderRadius: "20px",
                      }}
                    >
                      <span
                        style={{
                          display: "block",
                          fontWeight: "600",
                          fontSize: "16px",
                          padding: "1rem",
                        }}
                      >
                         Distribution by interest
                      </span>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "30% 30% 30%",
                          gap: "2px",
                          justifyContent: "center",
                        }}
                      >

                       <ApexDountChatNew
                          barColor="#045858"
                          data={[((Excursions / TotalProfiles) * 100).toFixed(0)]}
                          text="Excursions"
                        />
                          <ApexDountChatNew
                          barColor="#bb1ccc"
                          data={[((Shopping / TotalProfiles) * 100).toFixed(0)]}
                          text="Shopping"
                        />

                        <ApexDountChatNew
                          barColor="#283978"
                          data={[((Adventure / TotalProfiles) * 100).toFixed(0)]}
                          text="Sports"
                        />
 <ApexDountChatNew
                          barColor="#045858"
                          data={[((MusicEnthusiastFlagCount / TotalProfiles) * 100).toFixed(0)]}
                          text="Music"
                        />

<ApexDountChatNew
                          barColor="#283978"
                          data={[((Wellness / TotalProfiles) * 100).toFixed(0)]}
                          text="Wellness / Spa"
                        />

                        <ApexDountChatNew
                          barColor="#C3A5D0"
                          data={[((ArtAndCulture / TotalProfiles) * 100).toFixed(0)]}
                          text="Art/Culture"
                        />
                      

                        <ApexDountChatNew
                          barColor="#7E6E99"
                          data={[((Fashion / TotalProfiles) * 100).toFixed(0)]}
                          text="Fashion"
                        />

                        <ApexDountChatNew
                          barColor="#7E6E99"
                          data={[((TechEnthusiastFlagCount / TotalProfiles) * 100).toFixed(0)]}
                          text="Technology"
                        />

                       

                       

                      
                        <ApexDountChatNew
                          barColor="#069098"
                          data={[((Sustainability / TotalProfiles) * 100).toFixed(0)]}
                          text="Sustainability"
                        />
                      </div>
                    </span>
                  </>

                  <div style={{ display: "grid", gap: "10px" }}>
                    <span
                      style={{
                        background: "white",
                        padding: "10px",
                        borderRadius: "20px",
                      }}
                    >
                      <span
                        style={{
                          display: "block",
                          fontWeight: "600",
                          fontSize: "16px",
                          padding: "10px",
                        }}
                      >
                        Distribution by gender
                      </span>
                      <RangeBarChart
                        dataSet={dataSet}
                        MaxValue={highestValue}
                        category={maleFemaleCategory}
                      />
                    </span>

                    <span
                      style={{
                        background: "white",
                        padding: "10px",
                        borderRadius: "20px",
                      }}
                    >
                      <span
                        style={{
                          display: "block",
                          fontWeight: "600",
                          fontSize: "16px",
                          padding: "10px",
                        }}
                      >
                         Distribution by age group
                      </span>
                      <RangeBarChart
                        dataSet={dataSetRange}
                        MaxValue={highestValue}
                        category={lessthenGreaterThen}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="watermark"></div> */}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Psychographic;
