import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import "./Sidebar.scss";
import logo from "../../assets/imgs/Citizen-logo.svg";
import sideBarLogo from "../../assets/imgs/side-bar.svg";
import { useLocation, useHistory } from 'react-router-dom'; // Correct import
import Topnavbar from "./Topnavbar";
const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor:"#045858",
  overflowX: "hidden",
  "&& .headerText": {
    display: "block",
  },
  "&& .logoHeader": {
    display: "block",
  },
  "&& .textName": {
    display: "block",
  },
  "&& .testabc": {
    display: "block",
    backgroundImage:`url(${sideBarLogo})`,
    position:"absolute",
    bottom:"20px",
    width:"100%",
    height:"300px",
    backgroundSize: "contain", // Adds size control
    backgroundPosition: "center", // Sets the background image position
    backgroundRepeat: "no-repeat"
  },
  
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor:"#045858",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  "&& .headerText": {
    display: "none",
  },
  "&& .logoHeader": {
    display: "none",
  },
  "&& .textName": {
    display: "none",
  },
  
});

function getFormattedDate() {
  const date = new Date();

  // Get day, month, year, hours, and minutes
  let day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Add leading zero to minutes if necessary
  minutes = minutes < 10 ? '0' + minutes : minutes;

  // Determine AM or PM
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hours is 0, set to 12

  // Add ordinal suffix to day
  const suffix = getOrdinalSuffix(day);
  day += suffix;

  return `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
}

function getOrdinalSuffix(day) {
  if (11 <= day && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}

////console.log(getFormattedDate());


const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open ? openedMixin(theme) : closedMixin(theme)),
  "& .MuiDrawer-paper": open ? openedMixin(theme) : closedMixin(theme),
}));

const colorcode = [
  "#FF5733",
  "#0d420a",
  "#3357FF",
  "#FF33A1",
  "#33FFF0",
  "#F0FF33",
  "#FF8C33",
  "#8C33FF",
  "#33FF8C",
  "#FF3333",
  "#33A1FF",
];

const colorcode1 = [
  "#FF6F61",
  "#6B8E23",
  "#4682B4",
  "#D16F7B",
  "#40E0D0",
  "#F5DEB3",
  "#FF7F50",
  "#8A2BE2",
  "#98FB98",
  "#FF4500",
  "#1E90FF",
];

const menuItems = [
  // { name: "Overall Summary", icon: <HomeOutlinedIcon />, url: "/" },
  // { name: "Profile", icon: <AccountCircleOutlinedIcon />, url: "/profile" },
];

const menuItemsSpace = [
  { name: "Home", icon: <InboxIcon />, url: "/" },
  { name: "Demographic", icon: <InboxIcon />, url: "/Socio-Demographic-Distribution" },
  { name: "Psychographic", icon: <InboxIcon />, url: "/Psychographic" },
  { name: "Behavioral", icon: <InboxIcon />, url: "/BehavioralDistribution" },
];

const menuItemsResorces = [];

const menuItemsSettings = [
  // { name: "Settings", icon: <InboxIcon />, url: "/Settings" },
];

export default function SideNavBar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleDrawerToggle = () => setOpen((prevOpen) => !prevOpen);

  const renderMenuItems = (items, colorArray) => (
    <List>
      {items.map(({ name, icon, url }, index) => (
        <ListItem key={name} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={() => navigate.push(url)}
            sx={[
              { minHeight: 48, px: 2.5 },
              open
                ? { justifyContent: "initial" }
                : { justifyContent: "center" },
              currentPath === url
                ? {
                    color: "rgb(32, 101, 209)",
                    fontWeight: "700",
                    border: "4px solid #97E9E6",
                    borderTop: "none",
                    borderBottom: "none",
                    borderLeft: "none",
                  }
                : {},
            ]}
          >
            <ListItemIcon
              sx={[
                { minWidth: 0, justifyContent: "center" },
                open ? { mr: 3 } : { mr: "auto" },
                currentPath === url
                  ? {
                      color: "#bb1ccc",
                    }
                  : {},
              ]}
            >
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  background: colorArray[index],
                  textAlign: "center",
                  display: "grid",
                  alignItems: "center",
                  color: "white",
                  borderRadius: "6px",
                }}
              >
                {name.charAt(0)}
              </div>
            </ListItemIcon>
            <ListItemText
              className="textName"
              primary={name}
              title={name}
              sx={[
                open
                  ? { opacity: 1, fontWeight: "700",  color: currentPath === url ? "#97E9E6" : "white"                  }
                  : { opacity: 0, fontWeight: "700" },
              ]}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <div id="sidenavBarnew">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Topnavbar open={open}/>

        <Drawer   variant="permanent" open={open}>
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
            }}
           
          >
            <div className="logoHeader">
              <img width={134} src={logo} alt="Citizen" />
            </div>
            <div className="menuClickicon" onClick={handleDrawerToggle}>
              <MenuIcon sx={{ fontSize: "25px", width: "40px" }} />
            </div>
          </div>
          <div className="testabc"></div>
          <List>
            {menuItems.map(({ name, icon, url }) => (
              <ListItem key={name} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() =>navigate.push(url)}
                  sx={[
                    { minHeight: 48, px: 2.5 },
                    open
                      ? { justifyContent: "initial" }
                      : { justifyContent: "center" },
                    currentPath === url
                      ? {
                          color: "rgb(32, 101, 209)",
                          fontWeight: "700",
                          borderRight: "none",
                          borderTop: "none",
                          borderBottom: "none",
                          "::after": {
                            content: '""',
                            position: "absolute",
                            right: "0px",
                            top: "0",
                            width: "4px",
                            height: "100%",
                            backgroundColor: "#283978",
                            borderRadius: "8px",
                          },
                        }
                      : {},
                  ]}
                >
                  <ListItemIcon
                    sx={[
                      { minWidth: 0, justifyContent: "center" },
                      open ? { mr: 3 } : { mr: "auto" },
                      currentPath === url
                        ? {
                            color: "#283978",
                          }
                        : {},
                    ]}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    sx={[
                      open
                        ? { display: 'block', fontWeight: "600" }
                        : { display: 'none', fontWeight: "600" },
                    ]}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {/* <span className="headerText dash">Dashboard</span> */}
          {renderMenuItems(menuItemsSpace, colorcode1)}
          {/* <span className="headerText">Settings</span>
          {renderMenuItems(menuItemsSettings, colorcode1)} */}
        </Drawer>
      </Box>
    </div>
  );
}
