import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Getusers from "../../auth/Getusers";
import Search from "../Search/Search";
import { Avatar, Badge } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import Logout from "../ModelPopup/Logout";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${84}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

const Topnavbar = ({open}) => {

 function getFormattedDate() {
  const date = new Date();

  // Get day, month, year, hours, and minutes
  let day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Add leading zero to minutes if necessary
  minutes = minutes < 10 ? '0' + minutes : minutes;

  // Determine AM or PM
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hours is 0, set to 12

  // Add ordinal suffix to day
  const suffix = getOrdinalSuffix(day);
  day += suffix;

  return `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
}

function getOrdinalSuffix(day) {
  if (11 <= day && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}

////console.log(getFormattedDate());


  

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "white", boxShadow: "none", zIndex: "1" }}
        open={open}
        className="Tonabbar"
      >
        <Toolbar
          style={{
            justifyContent: "space-between",
            paddingTop: "20px",
            alignItems: "center",
            paddingBottom: "20px",
            height: "72px",
            paddingLeft:"10px"
          }}
        >
         

          <div
          
          >
            <Search/>

           

           

            
          </div>



          <div style={{ display: "flex", gap: "20px",color:"black",alignContent:"center",alignItems:'center' }}>
              {" "}
              <div className="time">{getFormattedDate()}</div>
              <div className="noti"><Notifications/></div>
              <div className="profile"><Getusers trim={true}/></div>
              <div className="noti">
                  <Logout/>
              </div>

            </div>

        </Toolbar>
      </AppBar>


    </Box>
  );
};

export default Topnavbar;
