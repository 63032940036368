import React, { useState } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CitizenData from '../../data/citizen.json'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useLocation, useHistory } from 'react-router-dom'; // Correct import


const Search =() => {
  // note: the id field is mandatory
  const [dataitem, setdataItem] = useState(CitizenData) 
  const History = useHistory()

  const byuserName = dataitem && dataitem.filter((item) => item.user !== undefined);
  // ////console.log(byuserName);
  const uniquebyuserName = [...new Set(byuserName)]


  const itemsData = uniquebyuserName.map((item) =>{
    return{
      name:item.user
    }
  })  
  
  const items = itemsData;


  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    ////console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    ////console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    ////console.log(item)
    History.push(`/user/${item.name}`)
  }

  const handleOnFocus = () => {
    ////console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <Link to={`${item.name}`}>
           <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        </Link>
      </>
    )
  }

  return (
    <div className="App">
      <header>
        <div style={{ width: 400 }}>
          <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            placeholder='Search by user'
            formatResult={formatResult}
          />
        </div>
      </header>
    </div>
  )
}

export default Search