import React from 'react';
import Chart from 'react-apexcharts';

const ApexDountChatNew = ({ data, text, barColor, labelColor = '#000' }) => {
  const options = {
    series: data,
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%', // Adjust this to control the size of the hollow center
        },
        dataLabels: {
          name: {
            fontSize: '14px', // Increase the font size to make the emoji stand out
            offsetY: -10, // Adjust the positioning of the text vertically
            show: true,
            color: barColor, // Set the label color from props
            formatter: function () {
              // Display the main emoji
              return `${text}`; // Main emoji displayed in the center of the chart
            },
          },
          value: {
            fontSize: '24px', // Font size for the value
            offsetY: 5, // Adjusting the position of the value
            color: barColor, // Set the value color
            show: true,
            style: {
              fontWeight: 'bold', // Change font weight to bold
            },
            formatter: function () {
              return data + '%'; // Show the data as a percentage
            },
          },
        },
      },
    },
    colors: [barColor], // Set the bar color from props
    legend: {
      position: 'bottom',
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 400,
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <Chart options={options} series={options.series} type="radialBar" height={280} />
    </div>
  );
};

export default ApexDountChatNew;
