import React from 'react';
import Chart from 'react-apexcharts';

const ApexDountChat = ({data,text,bgcolor}) => {
  const options = {
    series: data,
    chart: {
      type: 'radialBar'
    },
    colors: [`${bgcolor}`], // Place colors here

    plotOptions: {
      radialBar: {
        hollow: {
          size: '65%', // Adjust this to control the size of the hollow center
        },
        dataLabels: {
          name: {
            fontSize: '14px',
            show: true,
            formatter: function () {
              return `${text}`; // Changing the name to 'Naba'
            }
          },
          value: {
            fontSize: '16px',
          },
        },
      }
    },
    legend: {
      position: 'bottom',
      show: false
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 400
        },
      }
    }]
  };

  return (
    <div id="chart">
      <Chart options={options} series={options.series} type="radialBar" height={280}/>
    </div>
  );
};

export default ApexDountChat;
