import React from 'react';
import Chart from 'react-apexcharts';
import logo from '../../../assets/imgs/LoginScreen2.jpeg'

const RadialBarChart = ({ value, category, total }) => {
  // Ensure value is an array of objects and each item has a 'value' property
  const valueData = value && value.map(item => {
    return typeof item === 'object' && item.value !== undefined ? item.value : item;
  });
  const categoryData = value && value.map(item => item.name || '');

  // Sort only if value is an array of objects with 'value' property
  const sortedValue = Array.isArray(value) ? value.sort((a, b) => {
    return (b.value || 0) - (a.value || 0);
  }) : [];

  const options = {
    series: valueData, // Pass only values to series
    chart: {
      type: 'radialBar',
    },
   
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '10%',
          background: 'transparent',
        },
        dataLabels: {
          name: {
            fontSize: '22px',
          },
          value: {
            fontSize: '16px',
          },
          total: {
            show: false,
            label: 'Total',
            formatter: function () {
              return total; // Returns the sum of values
            },
          },
        },
        barLabels: {
          enabled: true,
          useSeriesColors: true,
          offsetX: -2,
          fontSize: '10px',
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + '%';
          },
        },
        track: {
          background: '#eee', // Set the track background color (optional)
          strokeWidth: '100%', // Set the stroke width to match the bar width
        }
      },
    },
    colors: [ '#3B93A5', '#045858', '#7E6E99', '#C3A5D0', '#F7B844'], // Define colors
    labels: categoryData, // Category labels for chart
    legend: {
      show: false, // Show legend
      position: 'bottom', // Position of legend
      fontSize: '14px',
      labels: {
        colors: ['#000'], // Legend label color
      },
      markers: {
        width: 10, // Legend marker width
        height: 10, // Legend marker height
      },
    },
  };

  return (
    <div id="chart">
      <Chart options={options} series={valueData} type="radialBar" height={380} />
    </div>
  );
};

export default RadialBarChart;
