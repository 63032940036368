import React, { useState } from "react";
import SideNavBar from "../../layout/Nav/Sidebar";
import { Box } from "@mui/material";
import "./BehavioralDistribution.scss";
import HeatmapChart from "../../layout/charts/HeatMap/HeatmapChart";
import RadarChart from "../../layout/charts/RadialBarChart/RadarChart";
import FunnelChart from "../../layout/charts/Funnel/FunnelChart";
import citizenData from "../../data/citizen.json";
import ApexDountChat from "../../layout/charts/Pie/ApexDountChat";
import RadialBarChart from "../../layout/charts/RadialBarChart/RadialBarChart";
import ColumnwithMarkers from "../../layout/charts/Bar/ColumnwithMarkers";

const BehavioralDistribution = () => {
  const [data, setData] = useState(citizenData);

  const MusicEnthusiastFlagCount = data
    ? data.filter(
        (item) =>
          item["Music enthusiast Flag"] === "Yes" &&
          item.buyjewellerybrand === "Yes"
      ).length
    : 0;

  const TechEnthusiastFlagCount = data
    ? data.filter((item) => item["Tech enthusiast Flag"] === "Yes").length
    : 0;

  const Wellness = data
    ? data.filter(
        (item) =>
          item["Interested in Massage & Spa or wellness retreat Flag"] === "Yes"
      ).length
    : 0;
  const WellnessSpa = data
    ? data.filter(
        (item) =>
          item["Interested in Massage & Spa or wellness retreat Flag"] ===
            "Yes" && item.buyjewellerybrand === "Yes"
      ).length
    : 0;

  const ArtAndCulture = data
    ? data.filter(
        (item) =>
          item["Love for Art and Culture"] === "Yes" &&
          item.buyjewellerybrand === "Yes"
      ).length
    : 0;

  const Sustainability = data
    ? data.filter((item) => item["Values Sustainability Flag"] === "Yes").length
    : 0;

  const Shoppingincruise = data
    ? data.filter((item) => item["Interested in Shopping in Cruise"] === "Yes")
        .length
    : 0;

  const ShoppingincruiseD = data
    ? data.filter(
        (item) =>
          item["Interested in Shopping in Cruise"] === "Yes" &&
          item["Loves Promotion / Discount / Duty Free"] === "Yes"
      ).length
    : 0;

  //////console.log("Shoppingincruise",Shoppingincruise)

  const Shopping = data
    ? data.filter((item) => item["Shopping Flag"] === "Yes").length
    : 0;

  const ShoppingNew = data
    ? data.filter(
        (item) =>
          item["Shopping Flag"] === "Yes" && item.buyjewellerybrand === "Yes"
      ).length
    : 0;

  const ShoppingD = data
    ? data.filter(
        (item) =>
          item["Shopping Flag"] === "Yes" &&
          item["Loves Promotion / Discount / Duty Free"] === "Yes"
      ).length
    : 0;

  const Adventure = data
    ? data.filter(
        (item) => item["Adventure Sports / Outdoor Sports enthusiast"] === "Yes"
      ).length
    : 0;

  const AdventureWithJewllery = data
    ? data.filter(
        (item) =>
          item[
            "Adventure Sports / Outdoor Sports enthusiast" &&
              item.buyjewellerybrand === "Yes"
          ] === "Yes"
      ).length
    : 0;

  const Excursions = data
    ? data.filter(
        (item) => item.Excursions === "Yes" && item.buyjewellerybrand === "Yes"
      ).length
    : 0;

  const watch = data
    ? data.filter((item) => item["Watch Brand"] === "Yes").length
    : 0;

  const watchD = data
    ? data.filter(
        (item) =>
          item["Watch Brand"] === "Yes" &&
          item["Loves Promotion / Discount / Duty Free"] === "Yes"
      ).length
    : 0;

  const watchAndJewlary = data
    ? data.filter((item) => item.buyjewellerybrand === "Yes").length
    : 0;

  const watchAndJewlaryD = data
    ? data.filter(
        (item) =>
          item.buyjewellerybrand === "Yes" &&
          item["Loves Promotion / Discount / Duty Free"] === "Yes"
      ).length
    : 0;

  //////console.log("watchAndJewlary",watchAndJewlary)

  const TotalProfiles = data
    ? data.filter((item) => item["Profile URL"]).length
    : 0;

  //////console.log(TotalProfiles)

  const Discount = data
    ? data.filter(
        (item) => item["Loves Promotion / Discount / Duty Free"] === "Yes"
      ).length
    : 0;

  // const dataItem = [MusicEnthusiastFlagCount,TechEnthusiastFlagCount,Wellness,Sustainability,Adventure,Shopping]
  const PersentageOfPeopleDiscount = [
    ((Discount / TotalProfiles) * 100).toFixed(0),
  ];
  const PersentageOfPeopleDiscount1 = (
    (Discount / TotalProfiles) *
    100
  ).toFixed(0);

  //////console.log("PersentageOfPeopleDiscount",TotalProfiles)
  //////console.log("Shopping",Shopping)
  //////console.log("watch",watch)
  //////console.log("Discount",Discount)

  const dataItem = [
    // { name: "Duty Free Shopping", value: (PersentageOfPeopleDiscount/PersentageOfPeopleDiscount * 100).toFixed(0) },
    { name: "Shopping", value: ((ShoppingD / Discount) * 100).toFixed(0) },
    {
      name: "Shopping in cruise",
      value: ((ShoppingincruiseD / Discount) * 100).toFixed(0),
    },
    {
      name: "Watch & Jewellery",
      value: ((watchAndJewlaryD / Discount) * 100).toFixed(0),
    },
    { name: "Watch", value: ((watchD / Discount) * 100).toFixed(0) },
  ];

  //////console.log(dataItem)
  ////console.log("Shopping with Discount", ShoppingD);
  ////console.log("Shopping in cruise with Discount", ShoppingincruiseD);
  ////console.log("Watch & Jewellery with Discount", watchAndJewlaryD);
  ////console.log("Watch with Discount", watchD);

  ////console.log("Shopping", Shopping);
  ////console.log("Shopping in cruise", Shoppingincruise);
  ////console.log("Watch & Jewellery", watchAndJewlary);
  ////console.log("Watch", watch);

  const dataItem1 = [
    {
      name: "Excursions",
      value: ((Excursions / watchAndJewlary) * 100).toFixed(0),
    },
    {
      name: "Shopping",
      value: ((ShoppingNew / watchAndJewlary) * 100).toFixed(0),
    },

    {
      name: "Music Enthusiast",
      value: ((MusicEnthusiastFlagCount / watchAndJewlary) * 100).toFixed(0),
    },
    {
      name: "Art/Culture",
      value: ((ArtAndCulture / watchAndJewlary) * 100).toFixed(0),
    },
    {
      name: "Wellness/Spa",
      value: ((WellnessSpa / watchAndJewlary) * 100).toFixed(0),
    },
  ];

  console.log("Excursions", Excursions);
  console.log("Shopping Excursions With And J", ShoppingNew);
  console.log("Adventure Sport", AdventureWithJewllery);
  console.log("MusicEnthusiastFlag", MusicEnthusiastFlagCount);
  console.log("Wellness And Spa", WellnessSpa);

  const brandname = data
    ? data
        .flatMap((item) =>
          item["Watch Brand Name"]
            ?.trim()
            .split(",")
            .map((brand) => {
              // Replace "Rolex Daytona" with "Rolex"
              return brand.trim() === "Rolex Daytona" ? "Rolex" : brand.trim();
            })
        ) // Split and trim each brand
        .filter((brand) => brand && brand !== " ") // Remove undefined, empty, or whitespace values
    : [];

  const statename = data
    ? data
        .filter((item) => item.Country === "USA") // Filter items where Country is 'USA'
        .map((item) => item.State) // Map to extract the State names
        .filter((state) => state && state.trim() !== "") // Remove any undefined, empty, or whitespace values
    : [];

  const uniquestatename = [...new Set(statename)];
  //////console.log(uniquestatename)

  const uniqueBrandName = [...new Set(brandname)];

  const brandnamecount = data
    ? data
        .filter((item) => item["Watch Brand Name"]) // Filter for items with defined "Watch Brand Name"
        .flatMap((item) =>
          item["Watch Brand Name"]
            .trim()
            .split(",")
            .map((brand) => brand.trim())
        ) // Split and trim each brand
        .filter((category) => category) // Remove undefined, empty, or whitespace values
        .reduce((acc, category) => {
          // Count "Rolex Daytona" under "Rolex"
          if (category === "Rolex Daytona") {
            acc["Rolex"] = (acc["Rolex"] || 0) + 1;
          } else {
            acc[category] = (acc[category] || 0) + 1; // Count occurrences of each other category
          }
          return acc;
        }, {})
    : {};

  const buyjewllerybrand = data
    ? data
        .filter((item) => item.buyjewellerybrand === "Yes") // Filter for items with defined "Watch Brand Name"
        .flatMap((item) =>
          item.State.trim()
            .split(",")
            .map((State) => State.trim())
        ) // Split and trim each brand
        .filter((category) => category) // Remove undefined, empty, or whitespace values
        .reduce((acc, category) => {
          // Count "Rolex Daytona" under "Rolex"
          acc[category] = (acc[category] || 0) + 1.07;
          return acc;
        }, {})
    : {};

  console.log(uniquestatename.length);

  const Totalcountry = data
  ? data.filter((item) => item.LocationCode !== undefined).length
  : 0;


  //////console.log(PersentageOfPeopleWatchandJwalary)
  const countofcountrycode1 = data
  ? data
      .filter((item) => item.State) // Filter for items with LocationCode
      .map((item) => item.State) // Prefix LocationCode with "US-"
      .filter((category) => category !== undefined) // Remove undefined values
      .reduce((acc, category) => {
        acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
        return acc;
      }, {})
  : {};

  const countofcountrycode = data
  ? data
      .filter((item) => item.LocationCode) // Filter for items with LocationCode
      .map((item) => item.LocationCode) // Prefix LocationCode with "US-"
      .filter((category) => category !== undefined) // Remove undefined values
      .reduce((acc, category) => {
        acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
        return acc;
      }, {})
  : {};
 
  const formattedStateData = Object.entries(countofcountrycode1).map(
    ([key, value]) => {
      const locationCode = key; // Extract the LocationCode (e.g., "FL")
      
      // console.log(locationCode)
      const foundItem = data.find((item) => item.State === locationCode);
      return {
        code:key,
        name: locationCode, // State name
        progress: ((value / Totalcountry) * 100).toFixed(0), // Occurrences count
      };
    }
  );

  const heatmapStatewise = uniquestatename.map((category) => {
    const percentage = (
      (buyjewllerybrand[category] / uniquestatename.length) *
      100
    ).toFixed(0);

    const StateData = formattedStateData.find((state) => state.name === category);
    const stateProgress = StateData ? StateData.progress : 0;    // console.log(percentage)

    return {
      x: category,
      y: isNaN(percentage) ? 0 : Number(percentage), // Return 0 if NaN, otherwise return the percentage as a number
      goals:[
        {
          name: 'Overall',
          value:  isNaN(stateProgress) ? 0 : Number(stateProgress),
          strokeHeight: 5,
          strokeColor: '#F7B844'
        }
      ]
    };
  });

  const TypeOfCBrandCategoryCount = uniqueBrandName.map((category) => ({
    x: category,
    y: ((brandnamecount[category] / TotalProfiles) * 100).toFixed(0) || 0, // Use the count or 0 if not present
  }));

  //////console.log(TypeOfCBrandCategoryCount);
  //////console.log(heatmapStatewise);

  ////////console.log(TypeOfCBrandCategoryCount);

  // const finalOutput = uniqueBrandName.map((category) => ({
  //   name: category,
  //   data: [
  //     buyjewllerybrand[category] || 0,  // Count for buyjewellerybrand
  //     brandnamecount[category] || 0     // Total count
  //   ]
  // }));

  // const Total

  const PeopleWatchandJwalary = watchAndJewlary;

  const PersentageOfPeopleWatchandJwalary = [
    ((PeopleWatchandJwalary / Discount) * 100).toFixed(0),
  ];
 

  console.log("buyjewllerybrand",buyjewllerybrand)
  console.log("formattedStateData",formattedStateData)


  const treemapdata = [...heatmapStatewise];



  console.log("treemapdata",treemapdata)

  const PersentageOfPeopleDiscountInShopping = [
    ((Shopping / TotalProfiles) * 100).toFixed(0),
  ];

  const PersentageOfPeopleDiscountInDutyfree = [
    ((ShoppingD / TotalProfiles) * 100).toFixed(0),
  ];

  const PersentageOfPeopleDiscountInwatchandjwellary = [
    ((watchAndJewlary / TotalProfiles) * 100).toFixed(0),
  ];

  const PersentageOfPeopleDiscountInWatch = [
    ((watch / TotalProfiles) * 100).toFixed(0),
  ];

  console.log("treemapdata", treemapdata);

  return (
    <div>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <SideNavBar />

        <Box className="mainCls" component="main" sx={{ flexGrow: 1, p: 1 }}>
          <div id="mainHome">
            <span className="headerTextNew">Behavioral Analysis</span>

            <div className="mainBehavioralDistribution">
              <div className="marginBox">
                <div className="firstBox4">
                  {/* <span
                    style={{
                      background: "white",
                      padding: "10px",
                      borderRadius: "20px",

                    }}
                  > <span
                  style={{
                    display:'block',
                      padding: "10px",
                      fontSize: "16px",
                  }}
                >
                  Distribution of Profile based on Shopping behaviour
                </span>
                    <FunnelChart data={dataItem} />
                  </span> */}
                  <span
                    style={{
                      background: "white",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      display: "grid",
                      height: "100%",
                      padding: "10px",
                      borderRadius: "20px",
                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        fontSize: "16px",
                        paddingLeft: "0px",
                      }}
                    >
                      Interested in shopping
                    </span>
                    <ApexDountChat
                      bgcolor="#045858"
                      data={PersentageOfPeopleDiscountInShopping}
                      text="Shopping"
                    />
                  </span>
                  <span
                    style={{
                      background: "white",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      display: "grid",
                      height: "100%",
                      padding: "10px",
                      borderRadius: "20px",
                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        fontSize: "16px",
                        paddingLeft: "0px",
                      }}
                    >
                      Interested in duty free shopping
                    </span>
                    <ApexDountChat
                      bgcolor="#7E6E99"
                      data={PersentageOfPeopleDiscountInDutyfree}
                      text="Duty Free Shopping"
                    />
                  </span>
                  <span
                    style={{
                      background: "white",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      display: "grid",
                      height: "100%",
                      padding: "10px",
                      borderRadius: "20px",
                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        fontSize: "16px",
                        paddingLeft: "0px",
                      }}
                    >
                      Interested in watch & jewelry
                    </span>
                    <ApexDountChat
                      bgcolor="#045858"
                      data={PersentageOfPeopleDiscountInwatchandjwellary}
                      text="Watch & Jewelry"
                    />
                  </span>
                  <span
                    style={{
                      background: "white",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      display: "grid",
                      height: "100%",
                      padding: "10px",
                      borderRadius: "20px",
                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        fontSize: "16px",
                        paddingLeft: "0px",
                      }}
                    >
                      Interested in watches only
                    </span>
                    <ApexDountChat
                      bgcolor="#7E6E99"
                      data={PersentageOfPeopleDiscountInWatch}
                      text="Watch"
                    />
                  </span>
                </div>
              </div>

              <div className="marginBox">
                <span style={{fontSize:"20px",paddingTop:"40px",display:"block"}}>
                  Behavioral analysis of travelers interested in watch and jewelry
                </span>
              </div>

              <div className="marginBox">
                <div className="firstBox3">
                  <span
                    style={{
                      background: "white",
                      padding: "10px",
                      borderRadius: "20px",
                    }}
                    className="item1"
                  >
                    <span
                      style={{
                        display: "block",
                        fontSize: "16px",
                        padding: "10px",
                      }}
                    >
                  
                      Distribution by interest
                    </span>
                    {/* <RadarChart data={dataItem1} /> */}
                    <RadialBarChart value={dataItem1} />
                  </span>

                  <span
                    style={{
                      background: "white",
                      padding: "10px",
                      borderRadius: "20px",
                    }}
                    className="item1"
                  >
                    <span
                      style={{
                        display: "block",
                        padding: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Distribution by location
                    </span>
                    {/* <HeatmapChart category={treemapdata} /> */}
                    <ColumnwithMarkers data={treemapdata}/>
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="watermark"></div> */}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default BehavioralDistribution;
