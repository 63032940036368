import React, { useState } from "react";
import SideNavBar from "../../layout/Nav/Sidebar";
import { Box } from "@mui/material";
import "./HomePage.scss";
import CitizenData from "../../data/citizen.json";
import Getusers from "../../auth/Getusers";
import HeaderImg2 from "../../assets/imgs/home-2nd-icon.svg";
import HeaderImg3 from "../../assets/imgs/home-3rd-icon.svg";
import HeaderImg1 from "../../assets/imgs/home-1st-icon.svg";
import DonutChart from "../../layout/charts/Pie/DonutChart";
// import DonutChart from '../../layout/charts/Pie/DonutChart'
const HomePage = () => {
  const [data, setData] = useState(CitizenData);
  const desiredOrder = ["Less then 45", "45 to 55", "55 to 60", "60 above"];
  const desiredOrdercruisetipes = [
    "Less Then 10",
    "10 to 20",
    "20 to 30",
    "More then 30",
  ];

  const TypeOfAgeCategory = data
    ? data
        .map((item) => item.TypeOfAgeCategory) // Extract the TypeOfAgeCategory
        .filter((category) => category !== undefined) // Remove undefined values
    : [];
  // const uniqueTypeOfAgeCategory = [...new Set(TypeOfAgeCategory)];
  const uniqueTypeOfAgeCategory = [...new Set(TypeOfAgeCategory)]
    .sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b))
    .reverse();

  const categoryCount = TypeOfAgeCategory.reduce((acc, category) => {
    acc[category] = (acc[category] || 0) + 1;
    return acc;
  }, {});

  const countOfmaleTypeOfAgeCategory = data
    ? data
        .filter((item) => item.Gender === "Male") // Filter for Male gender
        .map((item) => item.TypeOfAgeCategory) // Extract the TypeOfAgeCategory for Males
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  // Extract and count TypeOfAgeCategory for Females
  const countOfFemaleTypeOfAgeCategory = data
    ? data
        .filter((item) => item.Gender === "Female") // Filter for Female gender
        .map((item) => item.TypeOfAgeCategory) // Extract the TypeOfAgeCategory for Females
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  const maleTypeOfAgeCategoryArray = uniqueTypeOfAgeCategory.map(
    (category) => -+countOfmaleTypeOfAgeCategory[category] || 0
  );
  const findmaleTypeOfAgeCategoryArray = uniqueTypeOfAgeCategory.map(
    (category) => countOfmaleTypeOfAgeCategory[category] || 0
  );

  const maxValueMale = Math.max(...findmaleTypeOfAgeCategoryArray);

  // Convert female count object to array with all categories
  const femaleTypeOfAgeCategoryArray = uniqueTypeOfAgeCategory.map(
    (category) => countOfFemaleTypeOfAgeCategory[category] || 0
  );

  const maxValueFemale = Math.max(...femaleTypeOfAgeCategoryArray);

  const overallMaxValue = Math.max(maxValueMale, maxValueFemale);

  const typeOfCruiseTripsCategory = data
    ? data
        .map((item) => item.TypeOfCruiseTripsCategory) // Extract the TypeOfAgeCategory
        .filter((category) => category !== undefined) // Remove undefined values
    : [];

  // const uniqueTypeOfCruiseTripsCategory = [
  //   ...new Set(typeOfCruiseTripsCategory).sort(
  //     (a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b)
  // ];

  const uniqueTypeOfCruiseTripsCategory = [
    ...new Set(typeOfCruiseTripsCategory),
  ]
    .sort(
      (a, b) =>
        desiredOrdercruisetipes.indexOf(a) - desiredOrdercruisetipes.indexOf(b)
    )
    .reverse();

  const TypeOfCruiseTripsCategorycount = typeOfCruiseTripsCategory.reduce(
    (acc, category) => {
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    },
    {}
  );

  const TypeOfCruiseTripsCategoryCount = uniqueTypeOfCruiseTripsCategory.map(
    (category) => TypeOfCruiseTripsCategorycount[category] || 0
  );

  const MaritalStatusCategory = data
    ? data
        .map((item) => item["Marital Status"]) // Extract the Marital Status
        .filter((category) => category !== undefined) // Remove undefined values
    : [];

  const lenthOfMaritalStatusCategory = MaritalStatusCategory.length || 1;
  // Log the Marital Status categories

  const Alone = data
    ? data
        .filter((item) => item["Marital Status"] === "Alone") // Filter for Married
        .map((item) => item["Marital Status"]) // Extract the Marital Status
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  // Log the result

  const MarridWithwifeandKid = data
    ? data
        .filter(
          (item) => item["Marital Status"] === "Married" && item.Kids === "Yes"
        ) // Filter for Married
        .map((item) => item["Marital Status"]) // Extract the Marital Status
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  // Log the result

  const MarridWithWifewithNokid = data
    ? data
        .filter(
          (item) => item["Marital Status"] === "Married" && item.Kids === "No"
        ) // Filter for Married
        .map((item) => item["Marital Status"]) // Extract the Marital Status
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  const socialMedia = data
    ? data
        .map((item) => item["Type of Social media name"]) // Extract the TypeOfAgeCategory
        .filter((category) => category !== undefined) // Remove undefined values
    : [];

  const uniquesocialMedia = [...new Set(socialMedia)];

  const countOfSoicialmedia = data
    ? data
        .filter((item) => item["Type of Social media name"]) // Filter for Married
        .map((item) => item["Type of Social media name"]) // Extract the Marital Status
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  ////console.log(countOfSoicialmedia);

  const countofSoicalmedia = uniquesocialMedia.map(
    (category) =>
      (countOfSoicialmedia[category] / socialMedia.length) * 100 || 0
  );

  const countryCode = data
    ? data
        .map((item) => item.LocationCode) // Extract the TypeOfAgeCategory
        .filter((category) => category !== undefined) // Remove undefined values
    : [];

  const countofcountrycode = data
    ? data
        .filter((item) => item.LocationCode) // Filter for items with LocationCode
        .map((item) => `US-${item.LocationCode}`) // Prefix LocationCode with "US-"
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  const countofcountrycode1 = data
    ? data
        .filter((item) => item.LocationCode) // Filter for items with LocationCode
        .map((item) => item.LocationCode) // Prefix LocationCode with "US-"
        .filter((category) => category !== undefined) // Remove undefined values
        .reduce((acc, category) => {
          acc[category] = (acc[category] || 0) + 1; // Count occurrences of each category
          return acc;
        }, {})
    : {};

  // const formattedOutput = Object.entries(countofcountrycode)
  // .map(([key, value]) => `"${key}": ${value}`) // Add quotes around keys
  // .join(", "); // Join with a comma and space

  const formattedStateData = Object.entries(countofcountrycode1).map(
    ([key, value]) => {
      const locationCode = key; // Extract the LocationCode (e.g., "FL")
      const foundItem = data.find((item) => item.LocationCode === locationCode);
      const stateName = foundItem ? foundItem.StateName : "Unknown"; // Adjust based on your actual property name
      return {
        name: locationCode, // State name
        progress: value, // Occurrences count
      };
    }
  );

  const TotalProfiles = data
    ? data.filter((item) => item["Profile URL"]).length
    : 0;

  const TotalMale = data
    ? data.filter((item) => item.Gender === "Male").length
    : 0;

  const TotalFemale = data
    ? data.filter((item) => item.Gender === "Female").length
    : 0;

  const Totalcountry = data
    ? data.filter((item) => item.LocationCode !== undefined).length
    : 0;

  const TotalNothState = TotalProfiles - TotalMale - TotalFemale;
  return (
    <div>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <SideNavBar />

        <Box className="mainCls" component="main" sx={{ flexGrow: 1, p: 1 }}>
          <div id="mainHome">
            <span className="headerTextNewMain">Cruise Retail Market Intelligence Platform</span>

            <div className="mainHomePage">
              <div
                style={{
                  display: "grid",
                  gap: "30px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <div className="marginBox">
                    <div className="firstBox5">
                      <div  className="MainSection">
                           <div style={{display:"grid",padding:"1rem",textAlign:"left",justifyContent:"flex-start",gap:"5px",paddingTop:"2rem"}}>
                              <span style={{fontSize:"20px",color:"white"}}>Welcome Back!</span>
                              <span  style={{fontSize:"36px",color:"white",textAlign:"left"}}><Getusers className="HeaderName" name={true}/></span>
                              {/* <span style={{fontSize:"20px",color:"white"}}>Cruise Retail data market intelligence platform</span> */}
                              <span style={{color:"white"}}>This application provides cruise traveler profiling by demographic, psychographic and behavioral attributes based on the data collected from public forums </span>

                           </div>
                      </div>
                      {/* <div className="my-element12">
                      <div className="my-element">
                          <DonutChart data={TotalProfiles} text = "Profiles Scanned"/>
                      </div> 
                      </div> */}
                      
                    </div>
                  </div>

                  <div className="marginBox">
                    <div className="firstBox4">
                      <div className="box-item">
                        <span className="imgCls">
                          <img src={HeaderImg1} alt="img" />
                        </span>
                        <div style={{ display: "grid" }}>
                          <span className="card-title card-title-1">
                            Number of
                          </span>
                          <span className="card-title">
                            <b>Profiles Scanned</b>{" "}
                          </span>
                        </div>

                        <span className="card-values">{TotalProfiles}</span>
                      </div>
                      <div className="box-item">
                        <span className="imgCls">
                          <img src={HeaderImg1} alt="img" />
                        </span>
                        <div style={{ display: "grid" }}>
                          <span className="card-title card-title-1">
                            Number of
                          </span>
                          <span className="card-title">
                            <b>Posts analyzed</b>{" "}
                          </span>
                        </div>

                        <span className="card-values">120,220</span>
                      </div>
                      <div className="box-item">
                        <span className="imgCls">
                          <img src={HeaderImg2} alt="img" />
                        </span>
                        <div style={{ display: "grid" }}>
                          <span className="card-title card-title-1">
                          Number of
                          </span>
                          <span className="card-title">
                            <b>Blogs Included </b>{" "}
                          </span>
                        </div>

                        <span className="card-values">
                          {1}
                        </span>
                      </div>
                      <div className="box-item">
                        <span className="imgCls">
                          <img src={HeaderImg3} alt="img" />
                        </span>
                        <div style={{ display: "grid" }}>
                          <span className="card-title card-title-1">
                          Number of <b className="card-title">Cruise</b> 
                          </span>
                          <span className="card-title">
                            <b>  liners included</b>{" "}
                          </span>
                        </div>

                        <span className="card-values">
                          {1}
                        </span>
                      </div>
                      <div style={{backgroundColor:"transparent"}} className="box-item">
                       
                      </div>
                      <div style={{backgroundColor:"transparent"}} className="box-item">
                       
                       </div>
                    </div>
                  </div>


              
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default HomePage;
