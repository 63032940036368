// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import the getAuth function
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2O6fjPXogz5mcOOIF_aII_h1PTnAKuO8",
  authDomain: "csvtojson-dc9d9.firebaseapp.com",
  projectId: "csvtojson-dc9d9",
  storageBucket: "csvtojson-dc9d9.appspot.com",
  messagingSenderId: "1091959437872",
  appId: "1:1091959437872:web:fd215076863a6b15ea4bf5",
  measurementId: "G-ME184LDJN4"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);